import React from 'react'
import defaultBg from 'assets/post-sample-image.jpg'

const PostIntro = ({ bgImage, title, subtitle, postedBy }) => (
  <div>
    {bgImage && <div className="blog-image blog-page" style={{backgroundImage: `url(${bgImage})`}} />}
    <div className="post-content">
      <h1 className="blog-title">{title}</h1>
      <div>
        <div className="post-info">{postedBy}</div>
      </div>
      <div className="grey-rule"> </div>
    </div>
  </div>
)

export default PostIntro
