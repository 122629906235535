import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PostIntro from '../components/PostIntro'
import moment from 'moment'
import { parse, replaceTag } from 'utils/Parser.js'
import { Col, Container, Row } from 'reactstrap'
import { swapImgUrls } from '../utils/Parser'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.wordpressPost
    const introImage = getImageUrl(post)
    const { previous, next } = this.props.pageContext

    const html = post.content
    const imageNodes = this.props.data.allWordpressWpMedia.edges
    const imageObjects = imageNodes.map(({ node }) => ({
      src: node.localFile.childImageSharp.original.src,
      fileName: node.localFile.childImageSharp.fixed.originalName,
    }))
    const parsedHtml = swapImgUrls(html, imageObjects)

    return (
      <Layout>
        <SEO title={post.yoast.title} description={post.yoast.metadesc} />
        <div className="post-wrapper">
          <PostIntro
            title={post.title}
            bgImage={introImage}
            subtitle={replaceTag(post.excerpt, 'p', null)}
            postedBy={`Posted by ${post.author.name} on ${moment(
              post.date
            ).format('LL')}`}
          />
          <div className="post-content pt-0">
            {parsedHtml}
          </div>
        </div>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: '0',
          }}
        >
          <li>
            {next && (
              <Link className="button w-button" to={next.slug} rel="next">
                ← {next.title}
              </Link>
            )}
          </li>
          <li>
            {previous && (
              <Link className="button w-button" to={previous.slug} rel="prev">
                {previous.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

const getImageUrl = (post) => {
  try {
    return post.featured_media.localFile.childImageSharp.original.src
  } catch (e) {
    return null
  }
}

export const blogQuery = graphql`
  query blogPost($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      ...Post
    }
    allWordpressWpMedia {
      ...Media
    }
  }
`
