import React from 'react'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'

export const stripHtml = html => {
  return html.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, '')
}

export const prettyTrim = (text, maxLength) => {
  let shortened = text.substr(0, maxLength)
  return `${shortened.substr(
    0,
    Math.min(
      shortened.length,
      Math.min(shortened.lastIndexOf(' '), shortened.lastIndexOf(','))
    )
  )}${shortened.length > 100 ? '…' : ''}`
}

export const replaceTag = (html, tag, replaceTag) => {
  return ReactHtmlParser(html, {
    decodeEntities: true,
    transform: function transform(node, index) {
      if (replaceTag && node.type === 'tag' && node.name === tag) {
        node.name = replaceTag
        return convertNodeToElement(node, index, transform)
      } else {
        return node.children[0] ? node.children[0].data : null
      }
    },
  })
}

/**
 * This function replaces image urls based on file name
 * ImageObject example
 * {
 *   src: /static/path/image.jpg,
 *   fileName: image.jpg
 * }
 * */
export const swapImgUrls = (html, imageObjects) => {
  return ReactHtmlParser(html, {
    decodeEntities: true,
    transform: function transform(node, index) {
      if (node.type === 'tag' && node.name === 'img') {
        imageObjects.forEach(imgObj => {
          if (
            node.attribs.src.includes(
              imgObj.fileName
            )
          ) {
            node.attribs.src = imgObj.src
          }
        })
        node.attribs.srcSet = null
        return convertNodeToElement(node, index, transform)
      }
    },
  })
}

export const parse = html => {
  return ReactHtmlParser(html)
}
